<template>
  <main class="page_bg_grey blank-aside-js min-h-100vh w-100" :class = "(isOpenAside=='true')?'blank-aside':''">
    <SuppliersPage
      banner_location="games_home-top"
      supplier_router="GameSupplier"
      product_router="GameProduct"
      page_title="games"
    ></SuppliersPage>
  </main>
  </template>
  
  <script>
  import SuppliersPage from '@/components/endUser/suppliersPages/SuppliersPage.vue';
  
  export default {
    name: 'Game',
    components: {
      SuppliersPage
    },
  }
  </script>
